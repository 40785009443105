import { topicType } from "../enums/topicType";
import { latestSentCommandsDictionary } from "../dictionary/ua/latestSentCommandsDictionary";

export const topicTypeHelper = {
  getNameFromDictionary: function (commandTopic) {
    switch (commandTopic) {
      case topicType.scheduleCommand:
        return latestSentCommandsDictionary.scheduleCommandName;
      case topicType.radioSilenceCommand:
        return latestSentCommandsDictionary.radioSilenceCommandName;
      case topicType.radioAudioModeCommand:
        return latestSentCommandsDictionary.radioAudioModeCommandName;
      case topicType.gsmAudioModeCommand:
        return latestSentCommandsDictionary.gsmAudioModeCommandName;
      case topicType.relayCommand:
        return latestSentCommandsDictionary.relayCommandName;
      default:
        return null;
    }
  },
};
