import { latestSentCommandsDictionary } from "../dictionary/ua/latestSentCommandsDictionary";
import { commandStatus } from "../enums/commandStatus";

export const commandStatusHelper = {
  getStatusFromDictionary: function (status) {
    switch (status) {
      case commandStatus.delivered:
        return latestSentCommandsDictionary.deliveredCommandStatusText;
      case commandStatus.expired:
        return latestSentCommandsDictionary.expiredCommandStatusText;
      case commandStatus.pending:
        return latestSentCommandsDictionary.pendingCommandStatusText;
      case commandStatus.erroneous:
        return latestSentCommandsDictionary.erroneousCommandStatusText;
      default:
        return null;
    }
  },
};
