import config from "../config";
import { certificatesManagementDictionary } from "../dictionary/ua/certificatesManagementDictionary";
import { deviceStatusType } from "../enums/deviceStatus";

export const dateTimeHelper = {
  getCurrentLocalDateTime: () => new Date().toLocaleString(config.localization.ua),

  getLocalDateTime: (dateTime) =>
    dateTime
      ? new Date(dateTime).toLocaleString(config.localization.ua)
      : dateTime,

  getLocalDateTime: (dateTime, formatSettings) =>
    dateTime
      ? new Date(dateTime).toLocaleString(config.localization.ua, formatSettings)
      : dateTime,

  getLatestDateTime: (dateTimeArray) => {
    if (!Array.isArray(dateTimeArray) || dateTimeArray.length === 0) {
      return null;
    }

    const validDates = dateTimeArray
      .filter((date) => date && date !== deviceStatusType.notApplicable)
      .map((date) => new Date(date))
      .filter((date) => !isNaN(date));

    if (validDates.length === 0) {
      return null;
    }

    let latestDateTime = validDates[0];
    for (let i = 1; i < validDates.length; i++) {
      if (validDates[i] > latestDateTime) {
        latestDateTime = validDates[i];
      }
    }

    return latestDateTime.toISOString();
  },

  getExpiredDateTime: (dateTime) => {
    if (!dateTime) return dateTime;
    const date = new Date(dateTime);
    return date < new Date()
      ? certificatesManagementDictionary.ExpiredDateText
      : date.toLocaleString(config.localization.ua);
  },
};
