export const latestSentCommandsDictionary = {
    showLatestSentCommandsCheckboxText: "Показати статус виконання команд",
    scheduleCommandName: "Команда 'Сценарій'",
    radioSilenceCommandName: "Команда 'Час радіотиші'",
    radioAudioModeCommandName: "Команда 'Режим радіо'",
    gsmAudioModeCommandName: "Команда 'Режим для GSM'",
    relayCommandName: "Команда 'Реле'",
    deliveredCommandStatusText: "Доставлено",
    expiredCommandStatusText: "Недоставлено",
    pendingCommandStatusText: "Очікування відповіді",
    erroneousCommandStatusText: "Помилка",
    seconds: "с"
}