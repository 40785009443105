import React, { useEffect, useState } from "react";
import classes from "../../../css/pages/devices-list/latestSentCommandsPanel.module.css";
import { dateTimeHelper } from "../../../../helpers/dateTimeHelper";
import { iconManager } from "../../../../helpers/iconManager";
import { iconType } from "../../../../enums/iconType"; 
import { latestSentCommandsDictionary } from "../../../../dictionary/ua/latestSentCommandsDictionary";
import config from "../../../../config";
import { topicTypeHelper } from "../../../../helpers/topicTypeHelper";
import { commandStatus } from "../../../../enums/commandStatus";
import { commandStatusHelper } from "../../../../helpers/commandStatusHelper";
import { Tooltip } from "@mui/material";

const LatestSentCommandsPanel = ({ latestSentCommands }) => {
  const [showLatestSentCommandsCheckboxSelected, setShowLatestSentCommandsCheckboxSelected] = useState(true);
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp(Date.now());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getIconByCommandStatus = (status) => {
    switch (status) {
      case commandStatus.pending:
        return iconManager.getIconByType(iconType.pending);
      case commandStatus.delivered:
        return iconManager.getIconByType(iconType.delivered);
      case commandStatus.expired:
        return iconManager.getIconByType(iconType.notDelivered);
    }
  };

  const handleShowLatestSentCommandsClick = () => {
    setShowLatestSentCommandsCheckboxSelected((prev) => !prev);
  };

  const calculateRemainingTime = (createdDateTimeUtc) => {
    const elapsedTime = (currentTimestamp - new Date(createdDateTimeUtc).getTime()) / 1000;
    return Math.max((config.updateCommandStatusToExpiredCountdown) - elapsedTime, 0); 
  };

  return (
    <div className={classes["latest-sent-commands-panel-wrapper"]}>
      <div
        className={classes["show-latest-sent-commands-panel-checkbox-wrapper"]}
      >
        <label htmlFor="show-latest-sent-commands-checkbox">
          {latestSentCommandsDictionary.showLatestSentCommandsCheckboxText}
        </label>
        <input
          id="show-latest-sent-commands-checkbox"
          type="checkbox"
          className={classes["show-latest-sent-commands-checkbox"]}
          onChange={handleShowLatestSentCommandsClick}
          checked={showLatestSentCommandsCheckboxSelected}
        />
      </div>

      <div
        className={`${classes["latest-sent-commands-row-wrapper"]} ${
          showLatestSentCommandsCheckboxSelected ? classes.show : ""
        }`}
      >
        {latestSentCommands.length > 0 &&
          latestSentCommands
            .sort(
              (a, b) =>
                new Date(b.createdDateTimeUtc) - new Date(a.createdDateTimeUtc)
            )
            .map((command) => (
              <div
                key={command.id}
                className={`${classes["latest-sent-commands-row"]}`}
              >
                <div>
                  <Tooltip
                    title={commandStatusHelper.getStatusFromDictionary(command.status)}
                    arrow
                    placement="left"
                  >
                    {getIconByCommandStatus(command.status)}
                  </Tooltip>
                </div>
                <div>
                  {topicTypeHelper.getNameFromDictionary(command.topic)}
                </div>
                <div>
                  {command.status === commandStatus.pending &&
                  calculateRemainingTime(command.createdDateTimeUtc) > 0
                    ? `${Math.ceil(
                        calculateRemainingTime(command.createdDateTimeUtc)
                      )} ${latestSentCommandsDictionary.seconds}`
                    : ""}
                </div>
                <div>
                  {dateTimeHelper.getLocalDateTime(command.createdDateTimeUtc)}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default LatestSentCommandsPanel;
