import buttonClasses from "../../../css/assets/buttons/button.module.css";
import Button from "../../../assets/buttons/Button";
import { certificatesApi } from "../../../../api/certificatesApi";
import { useState } from "react";
import { certificatesManagementDictionary } from "../../../../dictionary/ua/certificatesManagementDictionary";
import { links } from "../../../../enums/links";

const GenerateCertificateButtonWrapper = ({
  deviceId,
  fetchCertificatesByDeviceId
}) => {
  const [pressed, setPressed] = useState(false);
  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const [disabled, setDisabled] = useState(false);
  
  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const handleClick = async () => {
    setDisabled(true);

    await certificatesApi.generate({
      deviceId: deviceId,
    }, links.getCertificatesManagementByDeviceEditLink(deviceId));
    
    await fetchCertificatesByDeviceId(deviceId);

    setDisabled(false);
  };

  return (
    <Button
      className={`${buttonClasses["generate-certificate-button"]} ${
        buttonClasses[setPressedClass()]
      }`}
      disabled={disabled}
      startIcon="start"
      onClick={() => handleClick()}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      {certificatesManagementDictionary.generateCertificateButtonLabel}
    </Button>
  );
};

export default GenerateCertificateButtonWrapper;
