import React, { useState } from "react";
import classes from "../../../../css/pages/devices-list/profiles/imitator/controlPanel.module.css";
import RadioSilenceCommand from "../../../../commands/RadioSilenceCommand";
import GsmAudioModeCommand from "../../../../commands/GsmAudioModeCommand";
import RadioAudioModeCommand from "../../../../commands/RadioAudioModeCommand";
import SchedulesCommand from "../../../../commands/SchedulesCommand";

const ControlPanel = ({
  profile,
  selectedDevicesClientIds,
  setDeviceList,
  selectAllDevicesCheckboxIndicator,
  scheduleList,
  hasPendingCommands
}) => {
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllDevicesClick = () => {
    setDeviceList((prevState) => {
      const updatedGrouped = { ...prevState };
      updatedGrouped[profile] = {
        array: prevState[profile].array.map((device) => ({
          ...device,
          selectedForSendingMessage: !selectAll,
        })),
      };

      return updatedGrouped;
    });

    setSelectAll(!selectAll);
  };

  return (
    <div className={classes["control-panel-wrapper"]}>
      <div className={classes["commands-row-wrapper"]}>
        <div className={classes["commands-row"]}>
          <RadioSilenceCommand
            selectedDevicesClientIds={selectedDevicesClientIds}
            setDeviceList={setDeviceList}
            hasPendingCommands={hasPendingCommands}
          />
          <GsmAudioModeCommand
            selectedDevicesClientIds={selectedDevicesClientIds}
            setDeviceList={setDeviceList}
            hasPendingCommands={hasPendingCommands}
          />
          <RadioAudioModeCommand
            selectedDevicesClientIds={selectedDevicesClientIds}
            setDeviceList={setDeviceList}
            hasPendingCommands={hasPendingCommands}
          />
          <SchedulesCommand
            selectedDevicesClientIds={selectedDevicesClientIds}
            scheduleList={scheduleList}
            setDeviceList={setDeviceList}
            hasPendingCommands={hasPendingCommands}
          />
        </div>
      </div>

      <div className={classes["select-all-devices-checkbox-wrapper"]}>
        <label htmlFor="select-all-devices-checkbox">
          Вибрати всі пристрої
        </label>
        <input
          id="select-all-checkbox"
          type="checkbox"
          className={classes["select-all-devices-checkbox"]}
          onChange={handleSelectAllDevicesClick}
          checked={selectAllDevicesCheckboxIndicator[profile]}
        />
      </div>
    </div>
  );
};

export default ControlPanel;