import React from "react";
import { deviceProfile } from "../../../enums/deviceProfile";
import ImitatorControlPanel from "./profiles/imitator/ControlPanel";
import RemoteSwitchControlPanel from "./profiles/remote-switch/ControlPanel";
import GsmGateControlPanel from "./profiles/gsm-gate/ControlPanel";

const profileControlPanelMap = {
  [deviceProfile.imitator]: ImitatorControlPanel,
  [deviceProfile.remoteSwitch]: RemoteSwitchControlPanel,
  [deviceProfile.gsmGate]: GsmGateControlPanel
};

const ControlPanelPicker = ({
  profile,
  selectedDevicesClientIds,
  setDeviceList,
  scheduleList,
  selectAllDevicesCheckboxIndicator,
  hasPendingCommands
}) => {
  const ControlPanelComponent = profileControlPanelMap[profile] || ImitatorControlPanel;

  return (
    <ControlPanelComponent
      profile={profile}
      selectedDevicesClientIds={selectedDevicesClientIds[profile]}
      setDeviceList={setDeviceList}
      scheduleList={scheduleList}
      selectAllDevicesCheckboxIndicator={selectAllDevicesCheckboxIndicator}
      hasPendingCommands={hasPendingCommands}
    />
  );
};

export default ControlPanelPicker;
