import React, { useEffect, useState } from "react";
import classes from "../css/commands/gsmAudioModeCommand.module.css";
import { topicType } from "../../enums/topicType";
import { getTopicName } from "../../helpers/getTopicName";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { gsmAudioModeCommandInputSchema } from "../../validation/validationSchemas";
import Button from "../assets/buttons/Button";
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";

import buttonClasses from "../css/assets/buttons/button.module.css";
import ConfirmIcon from "../assets/icons/ConfirmIcon";
import OffGsmAudioMode from "../assets/icons/gsmAudioMode/OffGsmAudioMode";
import EchoGsmAudioMode from "../assets/icons/gsmAudioMode/EchoGsmAudioMode";
import MonoGsmAudioMode from "../assets/icons/gsmAudioMode/MonoGsmAudioMode";
import { devicesListDictionary } from "../../dictionary/ua/devicesListDictionary";
import { links } from "../../enums/links";
import { mqttApi } from "../../api/mqttApi";
import { useDeviceListUpdater } from "../hooks/useDeviceListUpdater";

function GsmAudioModeCommand({ selectedDevicesClientIds, setDeviceList, hasPendingCommands }) {
  const [notValidationErrorText, setNotValidationError] = useState("");
  const [gsmAudioMode, setGsmAudioMode] = useState("");
  const [pressed, setPressed] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { updateDeviceListEntry } = useDeviceListUpdater(setDeviceList);
  const {
    register,
    handleSubmit,
    getFieldState,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(gsmAudioModeCommandInputSchema),
    mode: "onChange",
  });

  const onSubmit = (data) => {
    setGsmAudioMode("");
    setDisabled(true);

    const datas = {
      topics: [],
      payloads: [],
    };

    selectedDevicesClientIds.forEach((clientId) => {
      datas.topics.push(getTopicName(clientId, topicType.gsmAudioModeCommand));
      datas.payloads.push(String(data.gsmAudioMode));

      updateDeviceListEntry(clientId, topicType.gsmAudioModeCommand);
    });

    const response = mqttApi.sendMessage(datas, links.devicesLink);
    console.log(response);
  };

  const setPressedClass = () => {
    return pressed === true ? "pressed" : "";
  };

  const handleChange = (event) => {    
    if (gsmAudioMode && isAnyDeviceSelected()) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

    setGsmAudioMode(event.target.value);
  };

  const handleMouseDown = () => {
    setPressed(true);
    document.addEventListener("mouseup", () => handleMouseUp(), { once: true });
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  const isError = () => {
    return getFieldState("gsmAudioMode").invalid || notValidationErrorText !== "";
  }

  const isAnyDeviceSelected = () => selectedDevicesClientIds?.length > 0;
  
  useEffect(() => {    
      setDisabled(gsmAudioMode === "" || !isAnyDeviceSelected()|| hasPendingCommands);
  }, [isAnyDeviceSelected]);

  return (
    <div className={classes.command}>
      <span>{devicesListDictionary.gsmAudioModeDropDownLabel}</span>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes["input-container"]}>
          <FormControl
            className={classes["form-control"]}
            error={isError()}
          >
            <Select
              id="gsmAudioMode"
              className={classes["gsm-audio-mode-select"]}
              value={gsmAudioMode}
              size="small"
              {...register("gsmAudioMode")}
              onChange={handleChange}
            >
              <MenuItem value={0}><OffGsmAudioMode/></MenuItem>
              <MenuItem value={1}><EchoGsmAudioMode startIcon="end">{devicesListDictionary.ehoGsmAudioModeText}</EchoGsmAudioMode></MenuItem>
              <MenuItem value={2}><MonoGsmAudioMode startIcon="end">{devicesListDictionary.monoGsmAudioModeText}</MonoGsmAudioMode></MenuItem>
            </Select>
            <FormHelperText>{errors?.gsmAudioMode?.message}</FormHelperText>
          </FormControl>
          <FormHelperText className={classes["not-validation-error-text"]}>{notValidationErrorText}</FormHelperText>
          <Button
            className={`${buttonClasses["gsm-audio-mode-button"]} ${
              buttonClasses[setPressedClass()]
            }`}
            disabled={disabled}
            icon={<ConfirmIcon />}
            startIcon="start"
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            {devicesListDictionary.gsmAudioModeButtonText}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default GsmAudioModeCommand;
