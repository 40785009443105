import { useCallback } from "react";
import { commandStatus } from "../../enums/commandStatus";

export const useDeviceListUpdater = (setDeviceList) => {
  const updateDeviceListEntry = useCallback((clientId, topic, status = commandStatus.pending) => {
    setDeviceList((prevDeviceList) => {
      const updatedDeviceList = { ...prevDeviceList };

      for (const profile in updatedDeviceList) {
        const devices = updatedDeviceList[profile].array;
        const device = devices.find((d) => d.clientId === clientId);

        if (device) {
          if (!device.latestSentCommands) {
            device.latestSentCommands = [];
          }

          const commandIndex = device.latestSentCommands.findIndex(
            (cmd) => cmd.topic === topic
          );

          if (commandIndex !== -1) {
            device.latestSentCommands[commandIndex] = {
              ...device.latestSentCommands[commandIndex],
              status,
              createdDateTimeUtc: new Date(),
            };
          } else {
            device.latestSentCommands.unshift({
              id: crypto.randomUUID(),
              clientId,
              topic,
              status,
              createdDateTimeUtc: new Date(),
            });
          }
        }
      }

      return updatedDeviceList;
    });
  }, [setDeviceList]);

  return { updateDeviceListEntry };
};
