import { useEffect, useState } from "react";
import classes from "../../css/pages/devices-list/deviceList.module.css";
import { deviceApi } from "../../../api/deviceApi";
import { devicesListDictionary } from "../../../dictionary/ua/devicesListDictionary";
import { documentTitleDictionary } from "../../../dictionary/ua/documentTitleDictionary";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { scheduleApi } from "../../../api/scheduleApi";
import DeviceHeaderPicker from "./DeviceHeaderPicker";
import DeviceRowPicker from "./DeviceRowPicker";
import ControlPanelPicker from "./ControlPanelPicker";
import { deviceProfile, deviceProfileMapper } from "../../../enums/deviceProfile";
import useSetCurrentUserResult from "../../hooks/useSetCurrentUserResult";
import deviceMapper from "../../../mappers/deviceMapper";
import { links } from "../../../enums/links"
import DeviceMessagesPanel from "./DeviceMessagesPanel";
import useDeviceMessagesHandlers from "../../hooks/useDeviceMessagesHandlers";
import useLatestSentCommands from "../../hooks/useLatestSentCommands";
import LatestSentCommandsPanel from "./profiles/LatestSentCommandsPanel";
import { commandStatus } from "../../../enums/commandStatus";

const DeviceList = () => {
  useDocumentTitle(documentTitleDictionary.deviceListTitle + " - " + documentTitleDictionary.mainTitle);
  const [deviceList, setDeviceList] = useState([]);
  const setDeviceListWrapper = (newState) => {
    setDeviceList(newState);
  };

  const currentUserResult = useSetCurrentUserResult();

  const [scheduleList, setScheduleList] = useState([]);
  const getSelectAllDevicesCheckboxIndicator = () => {
    const result = {};
    Object.keys(deviceList).forEach((profile) => {
      const selectedCount = deviceList[profile].array.filter(
        (item) => item.selectedForSendingMessage
      ).length;

      result[profile] =
        selectedCount === deviceList[profile].array.length;
    });

    return result;
  };

  const getSelectedDevicesClientIds = () => {
    const result = {};
    Object.keys(deviceList).forEach((profile) => {
      const selectedClientIds = deviceList[profile].array.filter(
        (item) => item.selectedForSendingMessage
      ).map(item => item.clientId);

      result[profile] = selectedClientIds;
    });

    return result;
  }

  const fetchDeviceList = async () => {
    try {
      const response = await deviceApi.getWithStatuses(links.devicesLink);
        if (response.data?.length > 0) {
          const profileOrder = [ deviceProfile.imitator, deviceProfile.remoteSwitch, deviceProfile.gsmGate ];
          setDeviceList(groupDevicesByProfile(response.data, profileOrder));
        }
    } catch (error) {
      console.error("Error fetching device list:", error);
    }
  };

  const fetchScheduleList = async (search) => {
    try {
      const response = await scheduleApi.getOnlyOwnedByUser((search = ""), links.devicesLink);
      setScheduleList(response.data);
    } catch (error) {
      console.error("Error fetching schedule list:", error);
    }
  };

  const groupDevicesByProfile = (devices, profileOrder) => {
    const grouped = devices.reduce((grouped, device) => {
      const profile = device.profile;
      grouped[profile] = grouped[profile] || {
        array: [],
        selectedClientIds: [],
      };
      grouped[profile].array.push(device);
      return grouped;
    }, {});
    
    const sortedGrouped = {};
    profileOrder.forEach((profile) => {
      if (grouped[profile]) {
        sortedGrouped[profile] = grouped[profile];
      }
    });

    Object.keys(grouped)
      .filter((profile) => !profileOrder.includes(profile))
      .sort()
      .forEach((profile) => {
        sortedGrouped[profile] = grouped[profile];
      });

    return sortedGrouped;
  };

  const hasPendingCommands = (devicesArray) => {
    return devicesArray.some(device =>
      device.latestSentCommands.some(command => command.status === commandStatus.pending)
    );
  };

  useEffect(() => {
    const fetchDevicesAndSchedules = async () => {
      await Promise.all([
        fetchDeviceList(),
        fetchScheduleList(),
      ]);
    };

    fetchDevicesAndSchedules();
  }, []);

  useDeviceMessagesHandlers(setDeviceList);
  useLatestSentCommands(setDeviceList);

  if ((Object.keys(deviceList).length = 0))
    return <h2>{devicesListDictionary.noAvailableDeviceText}</h2>;

  return (
    currentUserResult.isAuthenticated && (
      <>
        {Object.keys(deviceList).map((profile) => (
          <div key={profile} className={classes["profile-container"]}>
            <div className={classes["header-container"]}>
              <div className={classes["aside-for-header"]}></div>
              <div className={classes["device-header"]}>
                <DeviceHeaderPicker profile={profile} />
              </div>
              <div className={classes["aside-for-header"]}></div>
            </div>

            <div className={classes["body-container"]}>
              <div className={classes["aside-for-body"]}></div>
              <div className={classes["device-row"]}>
                <span className={classes["profile-name"]}>
                  <h4>{deviceProfileMapper[profile]}</h4>
                </span>
                <div className={classes["rows-container"]}>
                  {deviceList[profile].array.length > 0 &&
                    deviceList[profile].array.map((device) => (
                      <>
                        <DeviceRowPicker
                          key={device.id}
                          device={device}
                          setDeviceList={setDeviceListWrapper}
                        />
                        <LatestSentCommandsPanel
                          latestSentCommands={device.latestSentCommands}
                        />
                        <DeviceMessagesPanel
                          deviceMessages={device.deviceMessages}
                        />
                      </>
                    ))}
                </div>
                <ControlPanelPicker
                  profile={profile}
                  selectedDevicesClientIds={getSelectedDevicesClientIds()}
                  setDeviceList={setDeviceListWrapper}
                  scheduleList={scheduleList}
                  selectAllDevicesCheckboxIndicator={getSelectAllDevicesCheckboxIndicator()}
                  hasPendingCommands={hasPendingCommands(deviceList[profile].array)}
                />
              </div>
              <div className={classes["aside-for-body"]}> </div>
            </div>
          </div>
        ))}
      </>
    )
  );
};

export default DeviceList;
