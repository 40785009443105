import useSignalR from "../hooks/useSignalR";

const useLatestSentCommands = (setDeviceList) => {
  const handleCommandStatusUpdate = (update) => {
    try {
    if (!update) return;

    setDeviceList((prevDeviceList) => {
      const updatedDeviceList = { ...prevDeviceList };

      for (const profile in updatedDeviceList) {
        const devices = updatedDeviceList[profile].array;
        const device = devices.find((device) => device.clientId === update.clientId);

        if (device) {
          if (!device.latestSentCommands) {
            device.latestSentCommands = [];
          }
          const commandIndex = device.latestSentCommands.findIndex(
            (cmd) => cmd.topic === update.topic
          );

          if (commandIndex !== -1) {
            device.latestSentCommands[commandIndex] = {
              ...device.latestSentCommands[commandIndex],
              status: update.status,
              updatedDateTimeUtc: update.updatedDateTimeUtc,
              remainingTime: 0,
            };
          } else {
            device.latestSentCommands.unshift({
              id: update.id,
              clientId: update.clientId,
              topic: update.topic,
              status: update.status,
              createdDateTimeUtc: update.createdDateTimeUtc,
              updatedDateTimeUtc: update.updatedDateTimeUtc,
              remainingTime: 0,
            });
          }

          break;
        }
      }

      return updatedDeviceList;
    });
  }
  catch(e){
    console.log('error:');
    console.error(e);
  }
    
  };

  const signalREvents = {
    CommandStatusChangedUpdate: handleCommandStatusUpdate
  };

  useSignalR(signalREvents);
};

export default useLatestSentCommands;
