import useSignalR from "../hooks/useSignalR";

const useDeviceMessagesHandlers = (setDeviceList) => {
  const handleDeviceStatusUpdate = (update) => {
    if (!update) return;
  
    setDeviceList((prevDeviceList) => {
      const updatedDeviceList = { ...prevDeviceList };
      const { deviceId, newProperties } = update;

      for (const profile in updatedDeviceList) {
        const profileGroup = updatedDeviceList[profile];
        const deviceIndex = profileGroup.array.findIndex((device) => device.id === deviceId);
  
        if (deviceIndex !== -1) {
          profileGroup.array[deviceIndex] = {
            ...profileGroup.array[deviceIndex],
            ...newProperties,
          };
          break;
        }
      }
  
      return updatedDeviceList;
    });
  };

  const handleDeviceMessageUpdate = (update) => {
    if (!update) return;
  
    setDeviceList((prevDeviceList) => {
      const updatedDeviceList = { ...prevDeviceList };
  
      for (const profile in updatedDeviceList) {
        const devices = updatedDeviceList[profile].array;
        const device = devices.find((device) => device.id === update.deviceId);
  
        if (device) {
          // Add the new message at the beginning of the deviceMessages array
          device.deviceMessages = [update, ...(device.deviceMessages || [])];
          break; // Exit loop once the device is found and updated
        }
      }
  
      return updatedDeviceList;
    });
  };
  
  const handleGsmInfoUpdate = (update) => {
    if (!update) return;
  
    const { deviceId, name, newProperties } = update;
  
    setDeviceList((prevDeviceList) => {
      const updatedDeviceList = { ...prevDeviceList };
  
      for (const profile in updatedDeviceList) {
        const devices = updatedDeviceList[profile].array;
  
        updatedDeviceList[profile].array = devices.map((device) =>
          device.id === deviceId
            ? {
                ...device,
                gsmInfos: device.gsmInfos.some((info) => info.name === name)
                  ? device.gsmInfos.map((info) =>
                      info.name === name ? { ...info, ...newProperties } : info
                    )
                  : [...(device.gsmInfos || []), { name: name, ...newProperties }],
              }
            : device
        );
      }
  
      return updatedDeviceList;
    });
  };

  const messageTypeHandlers = {
    LowBatteryVoltageLevel: handleDeviceMessageUpdate,
    DeviceGoesOnline: handleDeviceMessageUpdate,
    DeviceGoesOffline: handleDeviceMessageUpdate,
    LowGsmBalance: handleDeviceMessageUpdate,
    ScheduleFinished: handleDeviceMessageUpdate,
    MessageFromAdmin: handleDeviceMessageUpdate
  };

  const signalREvents = {
    DeviceStatusUpdate: handleDeviceStatusUpdate,
    DeviceMessageUpdate: (update) => {
      if (!update || !update.messageType) return;

      const handler = messageTypeHandlers[update.messageType];
      if (handler) {
        handler(update);
      } else {
        console.warn(`No handler found for messageType: ${update.messageType}`);
      }
    },
    GsmInfoUpdate: handleGsmInfoUpdate,
  };

  useSignalR(signalREvents);
};

export default useDeviceMessagesHandlers;
